<template>
  <div>
    <v-textarea
      auto-grow
      solo
      flat
      placeholder="eigene Notiz hier festhalten"
      :disabled="saving"
      :loading="loading"
      v-model="data"
      class="mb-0"
    ></v-textarea>
    <v-divider />
    <v-card-actions
      ><v-spacer /><v-btn @click="save" :loading="saving" small block text
        >speichern</v-btn
      ></v-card-actions
    >
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "NoteModule",

  data() {
    return {
      data: "",
      loading: false,
      saving: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.data = (
        await this.apiList({
          resource: "app/settings",
          query: "key=PersonalNote",
        })
      ).value;
      this.loading = false;
      this.$emit("loaded");
    },
    async save() {
      this.saving = true;
      await this.apiPost({
        resource: "app/settings",
        data: { key: "PersonalNote", value: this.data },
      });
      this.saving = false;
      this.fetchData();
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
